<template>
  <!--<Layout style="min-height: 100vh">
		<Sider hide-trigger :style="{background: '#F5F7F9',width:'40%'}">
			<div style="padding-top: 25px; width: 100% ">
				<div style="padding: 15px 15px; width: 100%; height: 200px; background: #fff">

				</div>
				<div>
					<Menu active-name="1-2" theme="light" width="auto" :open-names="['1']">
						<Submenu name="1">
							<template slot="title">
								<Icon type="ios-navigate"></Icon>
								Item 1
							</template>
							<MenuItem name="1-1">Option 1</MenuItem>
							<MenuItem name="1-2">Option 2</MenuItem>
							<MenuItem name="1-3">Option 3</MenuItem>
						</Submenu>
						<Submenu name="2">
							<template slot="title">
								<Icon type="ios-keypad"></Icon>
								Item 2
							</template>
							<MenuItem name="2-1">Option 1</MenuItem>
							<MenuItem name="2-2">Option 2</MenuItem>
						</Submenu>
						<Submenu name="3">
							<template slot="title">
								<Icon type="ios-analytics"></Icon>
								Item 3
							</template>
							<MenuItem name="3-1">Option 1</MenuItem>
							<MenuItem name="3-2">Option 2</MenuItem>
						</Submenu>
					</Menu>
				</div>
			</div>
		</Sider>
		<Layout :style="{padding: '0 24px 24px'}">
			<div style="font-size: 16px;height: 70px; float: left; padding: 25px 5px;">
			&lt;!&ndash;<Breadcrumb style="font-size: 16px;height: 70px; float: left; padding: 25px 5px;">
				<BreadcrumbItem to="/">
					<Icon type="ios-home-outline"></Icon> Overview
				</BreadcrumbItem>
				<BreadcrumbItem to="/">
					<Icon type="logo-buffer"></Icon> Repositories
				</BreadcrumbItem>
				<BreadcrumbItem to="/">
					<Icon type="ios-folder" /> Projects
				</BreadcrumbItem>
				<BreadcrumbItem to="/">
					<Icon type="ios-cube" /> Packages
				</BreadcrumbItem>
			</Breadcrumb>&ndash;&gt;
				<Menu mode="horizontal" theme="light" :active-name="activeTab" @on-select='menuSelect' >
					<MenuItem name="Code">
						<Icon type="ios-home-outline" to="/"></Icon> Overview
					</MenuItem>
					<MenuItem name="Issues">
						<Icon type="logo-buffer"></Icon> Repositories
					</MenuItem>
					<MenuItem name="PullRequests">
						<Icon type="ios-folder" /> Projects
					</MenuItem>
					<MenuItem name="PullRequests">
						<Icon type="ios-cube" /> Packages
					</MenuItem>
				</Menu>
			</div>

			<div style="padding-top: 15px">
					<Row class="project_container">
						<Col span="11">
							<Card>
								<p slot="title">The standard card</p>
								<p>Content of card</p>
								<p>Content of card</p>
								<p>Content of card</p>
							</Card>
						</Col>
						<Col span="11" offset="2">
							<Card>
								<p slot="title">Disable card with hover shadows</p>
								<p>Content of card</p>
								<p>Content of card</p>
								<p>Content of card</p>
							</Card>
						</Col>
					</Row>

				<Row class="project_container">
					<Col span="11">
						<Card>
							<p slot="title">The standard card</p>
							<p>Content of card</p>
							<p>Content of card</p>
							<p>Content of card</p>
						</Card>
					</Col>
					<Col span="11" offset="2">
						<Card>
							<p slot="title">Disable card with hover shadows</p>
							<p>Content of card</p>
							<p>Content of card</p>
							<p>Content of card</p>
						</Card>
					</Col>
				</Row>
			</div>

		</Layout>
	</Layout>-->
  <div style="width: 100%; background-color: white">
    <!--侧边栏  -->
    <Card style="width: 27%; margin-left: 3%; margin-top: 50px; float: left">
      <div style="padding-top: 25px; width: 100%">
        <div style="width: 100%; background: #fff">
          <Upload
            v-if="edit_tag"
            ref="upload"
            :format="['jpg', 'jpeg', 'png']"
            :before-upload="handleBeforeUpload"
            type="drag"
            action=""
            style="display: inline-block; width: 130px"
          >
            <Avatar v-if="faceUrl === ''" icon="ios-person" size="130" />
            <Avatar v-if="faceUrl !== ''" :src="faceUrl" size="130" />
          </Upload>
          <Avatar
            v-if="!edit_tag && faceUrl === ''"
            icon="ios-person"
            size="130"
          />
          <Avatar
            v-if="!edit_tag && faceUrl !== ''"
            :src="faceUrl"
            size="130"
          />
          <div style="margin-top: 20px">
            <p>{{ name }}</p>
            <div style="font-size: 1.05rem; font-weight: 500">
              {{ nickName }}
            </div>
          </div>
        </div>
        <Divider></Divider>
        <div
          v-show="!edit_tag"
          style="
            padding: 15px 10%;
            width: 100%;
            background: #fff;
            text-align: center;
          "
        >
          <div
            v-show="!edit_tag"
            style="
              width: 100%;
              float: left;
              font-size: 1rem;
              font-weight: 400;
              margin-bottom: 18px;
              text-align: left;
            "
          >
            {{ brief }}
          </div>

          <div
            style="font-size: 1.05rem; font-weight: 500; margin-bottom: 18px"
          >
            <Icon type="md-mail" size="25" style="margin-right: 13px" />Email
            <div style="font-weight: 400; font-size: 1rem">{{ email }}</div>
          </div>
          <div
            style="font-size: 1.05rem; font-weight: 500; margin-bottom: 18px"
          >
            <Icon type="md-person" size="25" style="margin-right: 13px" />Credit
            Point
            <div style="font-weight: 400; font-size: 1rem">
              {{ creditPoint }}
            </div>
          </div>
          <div
            style="
              width: 80%;
              float: left;
              margin-left: 10%;
              margin-top: 20px;
              margin-bottom: 30px;
            "
          >
            <Button long type="primary" @click="edtg">Edit profile</Button>
          </div>
        </div>

        <div
          v-show="edit_tag"
          style="
            padding: 15px 10%;
            width: 100%;
            background: #fff;
            text-align: center;
          "
        >
          <div style="width: 100%">
            <Input type="textarea" v-model="brief"> </Input>
          </div>
          <div
            style="
              width: 100%;
              margin-top: 10px;
              font-size: 1rem;
              margin-bottom: 18px;
              text-align: left;
              float: left;
            "
          >
            <div style="width: 40%; float: left">
              <Icon type="md-mail" size="15" style="margin-right: 5px" />
              Email
            </div>
            <Input style="font-weight: 400; font-size: 1rem" v-model="email">
            </Input>
          </div>
          <div
            style="
              width: 100%;
              margin-top: 10px;
              font-size: 1rem;
              margin-bottom: 18px;
              text-align: left;
              float: left;
            "
          >
            <div style="width: 40%; float: left">
              <Icon type="md-person" size="15" style="margin-right: 5px" />
              Username
            </div>
            <Input
              style="font-weight: 400; font-size: 1rem"
              v-model="userName"
            ></Input>
          </div>
          <div
            style="
              width: 100%;
              margin-top: 10px;
              font-size: 1rem;
              margin-bottom: 18px;
              text-align: left;
              float: left;
            "
          >
            <div style="width: 40%; float: left">
              <Icon type="md-person" size="15" style="margin-right: 5px" />
              Nickname
            </div>
            <Input
              style="font-weight: 400; font-size: 1rem"
              v-model="nickName"
            ></Input>
          </div>
          <div
            style="
              width: 100%;
              float: left;
              margin-top: 20px;
              margin-bottom: 30px;
            "
          >
            <Button @click="updateInfo"> Save</Button>
            <Button type="primary" @click="edtg2" style="margin-left: 15%"
              >Cancel</Button
            >
          </div>
        </div>
      </div>
    </Card>
    <!-- 主题页面 -Outline  -->
    <div
      style="
        width: 60%;
        background-color: white;
        float: left;
        margin-left: 20px;
      "
    >
      <!-- 切换栏  -->
      <div
        style="width: 100%; font-size: 16px; height: 70px; padding: 25px 5px"
      >
        <Menu
          mode="horizontal"
          theme="light"
          :active-name="activeTab"
          @on-select="menuSelect"
          style="z-index: 0"
        >
          <MenuItem name="Overview">
            <Icon type="ios-home-outline"></Icon>
            Overview
          </MenuItem>
          <!--
					<MenuItem name="Repositories">
						<Icon type="logo-buffer"></Icon>
						Repositories
					</MenuItem>-->

          <MenuItem name="Projects">
            <Icon type="ios-folder" />
            Projects
          </MenuItem>

          <MenuItem name="Account">
            <Icon type="md-pricetags" />
            Account
          </MenuItem>
        </Menu>
      </div>

      <div style="width: 100%; float: left; margin-bottom: 50px">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import App from "../App";
export default {
  data() {
    return {
      brief: "",
      imgPic: "",
      nickName: "yonghuming",
      name: "Zhang San",
      email: "zhangsan@163.com",
      creditPoint: 10,
      activeTab: "Overview",
      balance: 0,
      password: "",
      invest: "",
      faceUrl: "",
      edit_tag: false,
      userId: "",
      userName: "",
    };
  },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
    edtg() {
      this.edit_tag = true;
    },
    edtg2() {
      this.edit_tag = false;
      this.loadUserInfo();
    },
    updateInfo() {
      var Tself = this;
      var nd = {
        brief: Tself.brief,
        faceUrl: Tself.faceUrl,
        id: Tself.userId,
        name: Tself.nickName,
        password: Tself.password,
        username: Tself.userName,
      };
      let datas = JSON.stringify(nd);
      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "put",
        url: "/user/updateInfo",
        data: nd,
      })
        .then((response) => {
          //成功登陆
          var uf = response.data.data.items;
          Tself.$cookies.set("user_picture", uf.faceUrl);
          Tself.$cookies.set("user_name", uf.userName);
          this.edit_tag = false;
        })
        .catch((error) => {
          console.log(error);
          this.edit_tag = false;
        });
    },
    menuSelect(name) {
      switch (name) {
        case "Overview":
          this.$router.push("/userHome");
          break;
        case "Projects":
          this.$router.push("/userRepositories");
          break;
        /*case 'Projects':
						this.$router.push('/userProjects')
						break;*/
        case "Account":
          this.$router.push("/userPackages");
          break;
      }
    },
    handleBeforeUpload(file) {
      let fd = new FormData();
      fd.append("image", file);
      this.$axios
        .post("/uploadImage", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.faceUrl = response.data.data.filePath;
        })
        .catch((error) => {
          console.log(error);
        });

      return false;
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    loadUserInfo() {
      this.$axios
        .post("/user/findById", this.getCookie("user_id"), {
          headers: {
            "Content-Type": "text/xml",
          },
        })
        .then((response) => {
          //成功登陆
          let uf = response.data.data.items;
          this.userName = uf.username;
          this.userId = this.getCookie("user_id");
          this.name = uf.username;
          this.nickName = uf.name;
          this.brief = uf.brief;
          this.balance = uf.balance;
          this.creditPoint = uf.credit;
          this.email = uf.email;
          this.password = uf.password;
          this.invest = uf.invest;
          this.faceUrl = uf.faceUrl;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {},
  created() {
    this.loadUserInfo();
  },
};
</script>

<style></style>
